// React Basic and Bootstrap
import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

//import images
import about from "../../assets/images/gleam/about.png";

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <section className="section border-top" id="service">
          <Container>
            <Card className="rounded shadow border-0 bg-light overflow-hidden">
              <Row className="g-0 align-items-center">
                <Col lg="6">
                  <img src={about} className="rounded img-fluid" alt="" />
                </Col>

                <Col lg="6">
                  <CardBody className="section-title p-md-5">
                    <h4 className="title mb-4">Gleam Entertainment: <span className="text-primary">OSER</span></h4>
                    <p className="text-muted para-desc mb-0">
                      C'est avec un immense honneur que nous dévoilons le titre de notre tout premier projet « OSER »
                      Un film dédié à l'organisation MISS DIAMOND AFRICA à l’occasion de leur première édition.
                      Nous espérons sincèrement que vous apprécierez ce que nous avons préparé pour les jours à venir.
                      Nous vous invitons chaleureusement à vous abonner à nos pages Internet,
                      à partager et à aimer notre contenu, car c'est votre soutien qui nous donne de la force.
                      <br />
                      Quelle soit arrosée par l'amertume des séquelles du passé ou par le désir de surmonter 
                      les obstacles du quotidien, l'audace féminine peut déplacer les montagnes et changer le monde.
                      Retrouvez très prochainement sur vos plates formes préférés le film qui fera naître en vous 
                      le désir de conquérir le monde: OSER Un film de Mélissa Ngongang
                    </p>
                    <div className="mt-4 pt-2">
                      <a href="#contact" className="btn btn-outline-primary">
                        Reserver <i className="uil uil-angle-right-b"></i>
                      </a>
                    </div>
                  </CardBody>
                </Col>
              </Row>
            </Card>
          </Container>
        </section>
        <div className="position-relative">
          <div className="shape overflow-hidden text-light">
            <svg
              viewBox="0 0 2880 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default About;
