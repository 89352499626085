import React, { Component } from "react";
import { Row, Col } from "reactstrap";

//Import Images
import img1 from "../../assets/images/gleam/sponsor/1.jpeg";
import img2 from "../../assets/images/gleam/sponsor/2.jpeg";
import img3 from "../../assets/images/gleam/sponsor/3.jpeg";
import img4 from "../../assets/images/gleam/sponsor/4.jpeg";
import img5 from "../../assets/images/gleam/sponsor/5.jpeg";
import img6 from "../../assets/images/gleam/sponsor/6.jpeg";
import img7 from "../../assets/images/gleam/sponsor/7.jpeg";
import img8 from "../../assets/images/gleam/sponsor/8.jpeg";
import img9 from "../../assets/images/gleam/sponsor/9.jpeg";
import img10 from "../../assets/images/gleam/sponsor/10.jpeg";
import img11 from "../../assets/images/gleam/sponsor/11.jpeg";
import img12 from "../../assets/images/gleam/sponsor/12.jpeg";

const listImg = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
];

import SectionTitle from "./SectionTitle";

class Partners extends Component {
  render() {
    return (
      <React.Fragment>
        <SectionTitle
          title="Nos Sponsors"
          desc="Ils nous font confiance !"
        />
        <Row className="justify-content-center" id="partners">

          {listImg.map((item, key) => (
            <Col
              id={"partner" + (key + 1)}
              lg={2}
              md={2}
              xs={6}
              className="text-center"
              key={key}
            >
              <img src={item} alt="" style={{width:"60%", height:"auto"}} />
            </Col>
          ))}
          
        </Row>
      </React.Fragment>
    );
  }
}

export default Partners;
