import React, {useState} from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Alert,
  Form,
  Label,
  Input,
  FormGroup,
  Card,
  CardBody,
  FormFeedback
} from "reactstrap";

//Import Icons
import FeatherIcon from "feather-icons-react";

//Import Components
import SectionTitle from "../../components/Shared/SectionTitle";

import contact from "../../assets/images/gleam/info.jpeg";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { useFormspark } from "@formspark/use-formspark";

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

const ContactUs = () => {

  const [succeMsg, setsucceMsg] = useState(false);
  const [submit, submitting] = useFormspark({
    formId: "h820ZN6u"
  });

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: "",
      email: "",
      phone: "",
      paymentType: "Paypal",
      alreadyPaid: "No",
      role: "",
      comments: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name"),
      email: Yup.string().required("Email is required"),
      phone: Yup.string().required("Phone is required"),
      paymentType: Yup.string().required("Payment Type is required"),
      alreadyPaid: Yup.string().required("Already Paid is required"),
    }),
    onSubmit: (values) => {
      setsucceMsg(true)
      //console.log(values)
      submit({...values, id: "GLEAM-" + getRandomInt(100000)});
    }
  });

  const sendMail = () => {
    window.location.href = "mailto:ngongangmelisa@gmail.com";
  }

  const callNumber = () => {
    window.location.href = "tel:+4917648066893";
  }

  return (
    <React.Fragment>
      <Container>
        {/* section title */}
        <SectionTitle
          title="Reservation"
          desc="Reservez votre place pour le prochain événement."
        />

        <Row className="align-items-center">
          <Col
            lg="5"
            md={{ size: 6, order: 1 }}
            xs={{ order: 2 }}
            className="mt-4 pt-2"
          >
            <Card
              className="rounded shadow border-0"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <CardBody>
              <h4 className="card-title ">Reservation Ticket !</h4> 
              <p><b className="text-primary"> Info Payement:</b> <br/> 
              <b>PayPal:</b> bryan00lontsi@gmail.com <br/>
              <b>IBAN:</b> DE87100701240053914800 (Melisa Ngongang) <br/>
              </p>
         
                <div className="custom-form bg-white">
                  <div id="message"></div>
                  <Alert
                    color="info"
                    isOpen={succeMsg}
                    toggle={() => {
                      setsucceMsg(false);
                    }}
                  >
                    Reservation effectuée avec succès !
                  </Alert>
                  <Form
                    method="post"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                    name="contact-form"
                    id="contact-form"
                  >
                    <Row>
                      <Col lg="6">
                        <FormGroup className="mb-3">
                          <Label className="form-label">
                            Nom et prénom  <span className="text-danger">*</span>
                          </Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="user"
                                className="fea icon-sm icons"
                              />
                            </i>
                          </div>
                          <Input
                            name="name"
                            id="name"
                            type="text"
                            className="form-control ps-5"
                            placeholder="Nom et prénom :"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ""}
                            invalid={
                              validation.touched.name && validation.errors.name ? true : false
                            }
                          />
                          {validation.touched.name && validation.errors.name ? (
                            <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup className="mb-3">
                          <Label className="form-label">
                            Adresse Email  <span className="text-danger">*</span>
                          </Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="mail"
                                className="fea icon-sm icons"
                              />
                            </i>
                          </div>
                          <Input
                            name="email"
                            id="email2"
                            type="email"
                            className="form-control ps-5"
                            placeholder="Adresse Email  :"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email && validation.errors.email ? true : false
                            }
                          />
                          {validation.touched.email && validation.errors.email ? (
                            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                          ) : null}                          
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup className="mb-3">
                          <Label className="form-label">
                            Numéro de téléphone  <span className="text-danger">*</span>
                          </Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="phone"
                                className="fea icon-sm icons"
                              />
                            </i>
                          </div>
                          <Input
                            name="phone"
                            id="phone"
                            type="text"
                            className="form-control ps-5"
                            placeholder="Numéro de téléphone:"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.phone || ""}
                            invalid={
                              validation.touched.phone && validation.errors.phone ? true : false
                            }
                          />
                          {validation.touched.phone && validation.errors.phone ? (
                            <FormFeedback type="invalid">{validation.errors.phone}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup className="mb-3">
                          <Label className="form-label">
                            Moyen de Payement  <span className="text-danger">*</span>
                          </Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="credit-card"
                                className="fea icon-sm icons"
                              />
                            </i>
                          </div>
                          <Input
                            name="paymentType"
                            id="paymentType"
                            type="select"
                            className="form-control ps-5"
                            placeholder="Moyen de Payement  :"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.paymentType || ""}
                            invalid={
                              validation.touched.paymentType && validation.errors.paymentType ? true : false
                            }
                          >
                            <option value="Paypal">Paypal</option>
                            <option value="VirementBancaire">Virement Bancaire</option>
                          </Input>
                          {validation.touched.paymentType && validation.errors.paymentType ? (
                            <FormFeedback type="invalid">{validation.errors.paymentType}</FormFeedback>
                          ) : null}                          
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup className="mb-3">
                          <Label className="form-label">
                            Payement Effectué ? <span className="text-danger">*</span>
                          </Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="dollar-sign"
                                className="fea icon-sm icons"
                              />
                            </i>
                          </div>
                          <Input
                            name="alreadyPaid"
                            id="alreadyPaid"
                            type="select"
                            className="form-control ps-5"
                            placeholder="Nom et prénom :"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.alreadyPaid || ""}
                            invalid={
                              validation.touched.alreadyPaid && validation.errors.alreadyPaid ? true : false
                            }
                          >
                            <option value="No">Non</option>
                            <option value="Yes">Oui</option>
                          </Input>
                          {validation.touched.alreadyPaid && validation.errors.alreadyPaid ? (
                            <FormFeedback type="invalid">{validation.errors.alreadyPaid}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup className="mb-3">
                          <Label className="form-label">
                            Rôle  <span className="text-danger">*</span>
                          </Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="user-check"
                                className="fea icon-sm icons"
                              />
                            </i>
                          </div>
                          <Input
                            name="role"
                            id="role"
                            type="text"
                            className="form-control ps-5"
                            placeholder="Invité, Sponsor, Partenaire..."
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.role || ""}
                          />                         
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup className="mb-3">
                          <Label className="form-label">Commentaire:</Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="message-circle"
                                className="fea icon-sm icons"
                              />
                            </i>
                          </div>
                          <textarea
                            name="comments"
                            id="comments"
                            rows="4"
                            className="form-control ps-5"
                            placeholder="Your Message :"
                          ></textarea>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12" className="text-center">
                      <div className="d-grid">
                        <input
                          type="submit"
                          id="submit"
                          name="send"
                          className="submitBnt btn btn-primary"
                          value="Send Message"
                        />
                        <div id="simple-msg"></div>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col
            lg="7"
            md={{ size: 6, order: 2 }}
            xs={{ order: 1 }}
            className="mt-4 pt-2 order-1 order-md-2"
          >
            <Card className="border-0">
                  <CardBody className="p-0">
                    <img src={contact} className="img-fluid" alt="Landrick" />
                  </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container className="mt-100 mt-60">
            <Row>
              <Col md={4}>
                <Card className="border-0 text-center features feature-primary feature-clean">
                  <div className="icons text-center mx-auto">
                    <i className="uil uil-phone d-block rounded h3 mb-0"></i>
                  </div>
                  <div className="content mt-4">
                    <h5 className="fw-bold">Phone</h5>
                    {/* <p className="text-muted">
                      Start working with Landrick that can provide everything
                    </p> */}
                    <Link
                      to="#"
                      onClick={callNumber}
                      className="text-primary"
                    >
                      +49 176 48066893
                    </Link>
                  </div>
                </Card>
              </Col>

              <Col md={4} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                <Card className="border-0 text-center features feature-primary feature-clean">
                  <div className="icons text-center mx-auto">
                    <i className="uil uil-envelope d-block rounded h3 mb-0"></i>
                  </div>
                  <div className="content mt-4">
                    <h5 className="fw-bold">Email</h5>
                    {/* <p className="text-muted">
                      Start working with Landrick that can provide everything
                    </p> */}
                    <Link
                      to="#"
                      onClick={sendMail}
                      className="text-primary"
                    >
                      ngongangmelisa@gmail.com
                    </Link>
                  </div>
                </Card>
              </Col>

              <Col md={4} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                <Card className="border-0 text-center features feature-primary feature-clean">
                  <div className="icons text-center mx-auto">
                    <i className="uil uil-map-marker d-block rounded h3 mb-0"></i>
                  </div>
                  <div className="content mt-4">
                    <h5 className="fw-bold">Location</h5>
                    <p className="text-primary">
                      Bismarkallee 23, 14193 Berlin
                    </p>
                    {/* <Link to="#" className="video-play-icon h6 text-primary">
                      View on Google map
                    </Link> */}
                  </div>
                </Card>
              </Col>
            </Row>
          </Container>
    </React.Fragment>
  );
};

export default ContactUs;
